export const customersInitialState = {
    list: [],
    tenantList: [],
    tenantDetails: null,
    userList: [],
    deployedLicensesGrowth: [],
    newCustomersValues: {},
    detectedUrlLegend: {},
    detectedUrlValues: {},
    verifiedEventsLegends: {},
    verifiedEventsValues: {},
    activeUserCount: 0,
    userListFlag: false,
    createCustomerFlag: false,
    needRefreshToken: false,
    selected: null,
    users: [{ name: 'User: All', value: 'allusers', id: 'allusers' }],
    searchedUsers: [{ name: 'User: All', value: 'allusers', id: 'allusers' }],
    brands: [{ name: 'Brand: All', value: 'allbrands', id: 'allbrands' }],
    usersCount: 0,
    userDetails: null,
    licenseUsers: [],
    totalSize: 0,
    filters: {
      offset: 0,
      pageSize: 5
    },
    customerDetails: null,
    userMsg: null,
    statusCode: null,
    loading: false,
    isGetUserOnce: true,
    showWelcomePopup: false,
    alertConfig: {},
    whitelistDomains: [],
    whitelistDomainFlag: false,
    addWhitelistDomainFlag: false,
    removeWhitelistDomainFlag: false,
    whitelistUrls: [],
    getWhitelistUrlsFlag: false,
    addWhitelistUrlFlag: false,
    removeWhitelistUrlFlag: false,
    blacklistUrls: [],
    blacklistUrlFlag: false,
    apiKey: null,
    apiKeyExpiryDate: null,
    tenantApiKey: null,
    tenantApiKeyExpiryDate: null,
    newCustomers: 0,
    licenses: {},
    detectedUrlEvents: {},
    verifiedEvents: {},
    urlUsers: {},
    deployedLicensesPage: {
      gridLoading: false,
      dayChartLoading: false,
      monthChartLoading: false,
      yearChartLoading: false,
    },
    logo : "",
    logoLoading: true,
    tenantWhitelabelInfo: {
      supportEmail: '',
      brandName: '',
      logoFileName: '',
      brandLogo: '', 
      isCustomized: false 
    },
    isCustomized: false,
    scanConfigSettings : {
      mmEnabled: false,
      modelCode: 'PP1',
      demomodeEnabled: false,
      ppEnabled: true
    },
    multimodalSettingsLoading: true,
    multimodalSettingsMsg : '',
    brandPolicies: [],
    brandPoliciesTotalRec : 0,
    brandPoliciesLoading: false,
    brandPoliciesMsg: '',
    brandPoliciesTotal: 0,
    brandPoliciesTotalLoading: false,
    brandPoliciesTotalMsg: '',
    addEditBrandPolicyReq: false,
    addEditBrandPolicyMsg: '',
    addEditBPError : false,
    scanConfigSettingsLoading: true,
    scanConfigSettingsMsg : ''
  }