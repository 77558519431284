import React, { Component } from 'react';
import Style from './licenseManagement.module.css';
// import SelectBox from './common/selectbox/selectBox';
import { connect } from 'react-redux';
import { getUsers, getExtensionUsersCount, getDeployedLicensesGrowth, getBreakDowns, setCurrentCustomer, getSearchExtensionUserById, unSetLicenseUserListFailMsg } from '../store/entities/customers'; 
import EnterIcon from '../assets/Enter_Arrow_Right.svg';
import ConfirmPopup from './common/popover/confirmPopup'
import SelectBox from './common/selectbox/selectBox'

// import {
//   getExtensionUsersCount,
//   getSearchExtensionUserById,
//   getOnceUsers,
//   setCurrentCustomer,
//   loadNextUsers,
//   loadPrevUsers,
//   getUsers,
//   getCustomerDetails,
//   getBreakDowns,
//   getDeployedLicensesGrowth,
//   getActiveUserGrowth,
//   getActiveUsersCount,
//   setCustomersFilters,
// } from '../store/entities/customers'
// import { formatDetectionDatetime } from '../utilities/datetime'
// import Paginator from './common/paginator/paginator'
// import { produce } from 'immer';
// import { isGlobalView } from '../utilities/config'
import GridComponent from './common/grid/GridComponent';
import ProfileSvg from '../assets/profile.svg'
// import EnterIcon from '../assets/Enter_Arrow_Right.svg'
// import { customersInitialState } from '../store/initialState/customersState'
// import './incidentCharts.scss'
import ChartLineBuild from './charts/ChartLineBuild';
import LMBrowserChartWrapper from './LMBrowserChartWrapper';
// import Tooltip from './common/popover/tooltip'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
// import DropDown from './common/selectbox/dropDown';
import { isGlobalView } from '../utilities/config';
// import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';


let tabs = {
  month: {
    name: "month",
    timespan: 12
  },
  year: {
    name: "year",
    timespan: 5
  },
  day: {
    name: "day",
    timespan: 30
  },
}

class LicenseManagement extends Component {

  state = {
    customerChanged: false,
    // data: [],
    // userList: [],
    pageSize: 6,
    filters: {},
    selectedChartTab: tabs.month.name,
    chartFilters: {
      getDataByPeriod: tabs.month.name,
      timespan: tabs.month.timespan,
      getDataByBrowser: 0,
      getDataByOS: 0,
    },
    licenseUserApiFailMsg: {
      icon: '',
      type: 'error',
      title: 'Oops! Something went wrong.',
      description: 'There was an error while fetching the user list. Please try Refreshing the Page.',
      buttons: 'O',
  }
  
  }
  // state = {
  //   selectedTab: 'License Management',
  //   setCustomerOnce: true,
  //   customerChanged: false,
  //   selectedCustomerID: '',
  //   selectedExtensionUser: {
  //     name: 'User: All',
  //     value: 'allusers',
  //     id: 'allusers',
  //   },
  //   filters: this.props.filters,
  //   selectedChartTab: this.tabs.month.name,
  //   chartFilters: {
  //     getDataByPeriod: this.tabs.month.name,
  //     timespan: this.tabs.month.timespan,
  //     getDataByBrowser: 0,
  //     getDataByOS: 0,
  //   },
  // }

  onActionClick = (row) => {
    // console.log("row",row);
    if(!isGlobalView(this.props.selectedCustomer?.organizationName)) {
     return this.props.history.push(
        `/customers/${this.props.selectedCustomer.id}/licensemanagement/${row.id}`,
      )
    }
    else {
      return this.props.history.push(
        `/tenants/${this.props.selectedCustomer.id}/${row.customerId}/licensemanagement/${row.id}`,
      )
    }
  }

 usersGridDef = {
      name: 'userList',
      columns: [
          {fieldName: 'name', displayName: 'User', width: '30%', className: Style.boldFont},
          {fieldName: 'created', displayName: 'Detail', dataType: 'date', subType: 'datetime', prefix:'Created At', width: '62%', className: Style.italicFont},
          {fieldName: 'action', displayName: 'Action', dataType: 'image', src: EnterIcon, width: '50px', onClick: this.onActionClick,}
      ]
    }
  
  getNextPageData = (pageNo, pageSize) => {
    this.setData(pageNo, pageSize);
  }

  getPrevPageData = (pageNo, pageSize) => {
    this.setData(pageNo, pageSize);
  }

  setData = (pageNo, pageSize) => {
    const start = (pageNo - 1) * (pageSize - 1)
    const end = pageNo * pageSize
    // this.setState({data: typeof this.state.userList === "object" && this.state.userList instanceof Array ? this.state.userList.slice(start, end) : [] , offset: start, pageSize: end}); 
    this.setState({filters: {offset: start, pageSize: pageSize}}, () => { this.props.getUsers(this.state.filters, this.props.match.params.id, this.props.selectedCustomer?.defaultCustomerId, isGlobalView(this.props.selectedCustomer?.organizationName)); }) ;
  }

  rowClick = (row) => {
    // console.log(row);
  }

  populateCustomerData = () => {    
    // this.props.getUsers(this.state.filters, this.props.match.params.id, false);
    if(this.props.selectedCustomer) {
      this.props.getExtensionUsersCount(this.props.match.params.id, this.props.selectedCustomer?.defaultCustomerId, isGlobalView(this.props.selectedCustomer?.organizationName));
      this.props.getDeployedLicensesGrowth(this.state.chartFilters, this.props.match.params.id, this.props.selectedCustomer?.defaultCustomerId, isGlobalView(this.props.selectedCustomer?.organizationName));
      this.props.getBreakDowns( this.props.match.params.id, this.props.selectedCustomer?.defaultCustomerId, isGlobalView(this.props.selectedCustomer?.organizationName))
    }
    
  }

  componentDidMount = () => {
    if (this.props.selectedCustomer && this.props.selectedCustomer.id === this.props.match.params.id) {
      this.populateCustomerData();
    }
    // ------------------------- OLD Code -------------------------
    // let { selectedCustomer, filters } = this.props
    // let selectedId =  this.props.match.params.id
    
    // if (
    //   selectedCustomer &&
    //   //selectedCustomer.id === this.props.match.params.id &&
    //   document.getElementById('listLM') === null
    // ) {
    //   this.props.getDeployedLicensesGrowth(
    //     this.state.chartFilters,
    //     selectedId,
    //     isGlobalView(selectedCustomer.organizationName),
    //   )
    //   // if (this.props.tokenRoles === 'PixmAdmin') {
    //   //   this.props.getActiveUserGrowth(
    //   //     this.state.chartFilters,
    //   //     selectedCustomer.id,
    //   //     isGlobalView(selectedCustomer.organizationName),
    //   //   )
    //   //   this.props.getActiveUsersCount(
    //   //     selectedCustomer.id,
    //   //     isGlobalView(selectedCustomer.organizationName),
    //   //   )
    //   // }
    //   //this.initiateFilters()
    //   this.props.getUsers(
    //     this.state.filters,
    //     selectedId,
    //     isGlobalView(selectedCustomer.organizationName),
    //   )
    //   this.props.getExtensionUsersCount(
    //     selectedId,
    //     isGlobalView(selectedCustomer.organizationName),
    //   )
    //   this.props.getCustomerDetails(selectedCustomer.id)
    //   this.props.getBreakDowns(
    //     selectedId,
    //     isGlobalView(selectedCustomer.organizationName),
    //   )
    // } else {
    //   this.setState(
    //     {
    //       selectedExtensionUser: {
    //         name: 'User: All',
    //         value: 'allusers',
    //         id: 'allusers',
    //       },
    //       filters: customersInitialState.filters,
    //     },
    //     () => {
    //       // [componentDidMount]:customer details have not been recieved.
    //       // selectedCustomer.id is passed as undefined and getUsers() API call fails, hence commented.

    //       // this.props.getUsers(
    //       //   this.state.filters,
    //       //   selectedCustomer.id,
    //       //   // isGlobalView(selectedCustomer.organizationName),
    //       //   true,
    //       // )
    //     },
    //   )
    // }
  }

  // initiateFilters = () => {
  //   this.setState({ filters: customersInitialState.filters })
  // }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.usersList !== this.props.usersList) {
    //   this.setState({userList: this.props.usersList});
    //   // this.setData(1, this.state.pageSize);
    // }
    // if(prevState.userList !== this.state.userList) {
    //   // this.setData(1, this.state.pageSize);
    // }

    let arrSelectedCustomer = this.props.orgList.filter((ele) => ele.id === this.props.match.params.id);

    if (this.props.selectedCustomer !== prevProps.selectedCustomer && this.props.selectedCustomer.id === arrSelectedCustomer[0].id ) {
      this.props.setCurrentCustomer(arrSelectedCustomer[0]);
      this.populateCustomerData();
      this.state.customerChanged = true
    } else if(this.props.selectedCustomer && arrSelectedCustomer[0] && this.props.selectedCustomer.id !== arrSelectedCustomer[0].id) {
      this.props.setCurrentCustomer(arrSelectedCustomer[0]);
      this.state.customerChanged = true
    }

    // if(this.props.selectedCustomer != prevProps.selectedCustomer) {
    //   this.populateCustomerData();
    // }
    // ------------------------- OLD Code -------------------------
    // let { selectedCustomer } = this.props
    // if (this.props.selectedCustomer !== prevProps.selectedCustomer &&
    //   selectedCustomer.id === this.props.match.params.id ) {
    //   this.initiateFilters()
    //   this.setState({
    //     customerChanged: true,
    //     selectedExtensionUser: {
    //       name: 'User: All',
    //       value: 'allusers',
    //       id: 'allusers',
    //     },
    //   })
    //   this.props.getDeployedLicensesGrowth(
    //     this.state.chartFilters,
    //     selectedCustomer.id,
    //     isGlobalView(selectedCustomer.organizationName),
    //   )

    //   this.props.getBreakDowns(
    //     selectedCustomer.id,
    //     isGlobalView(selectedCustomer.organizationName),
    //   )
    // }

    // if (prevProps !== this.props && this.state.setCustomerOnce) {
    //   this.setState({
    //     setCustomerOnce: false,
    //   })

    //   let filtredElement = this.props.orgList.filter(
    //     (ele) => ele.id === this.props.match.params.id,
    //   )

    //   if (
    //     this.props.selectedCustomer &&
    //     filtredElement[0] &&
    //     filtredElement[0].id !== this.props.selectedCustomer.id
    //   ) {
    //     this.props.setCurrentCustomer(filtredElement[0])
    //   }
    // }
    // if (this.props.filters !== prevProps.filters) {
    //   this.state.filters = this.props.filters
    // }
    // if (
    //   prevProps.selectedCustomer !== this.props.selectedCustomer &&
    //   selectedCustomer.id === this.props.match.params.id
    // ) {

    //   this.props.getUsers(
    //     this.state.filters,
    //     selectedCustomer.id,
    //     isGlobalView(this.props.selectedCustomer.organizationName),
    //   )
    //   this.props.getExtensionUsersCount(
    //     selectedCustomer.id,
    //     isGlobalView(this.props.selectedCustomer.organizationName),
    //   )
    //   this.props.getCustomerDetails(selectedCustomer.id)
    // }
  }

  // removeFilter = (key) => {
  //   this.state.filters = produce(this.state.filters, (draftState) => {
  //     delete draftState[key]
  //   })
  //   // this.updateStateInProps()
  // }

  // setFilters = (changedValues) => {
  //   this.state.filters = produce(this.state.filters, (draftState) => {
  //     Object.keys(changedValues).map((property) => {
  //       draftState[property] = changedValues[property]
  //     })
  //   })
  //   // this.updateStateInProps()
  // }

  // updateStateInProps = () => {
  //   this.props.setCustomersFilters(this.state.filters)
  // }

  handleCustomerChange = () => {
    this.setState({
      customerChanged: false,
    })
  }

  handleSelect = (selectedOption, value) => {
    // let { selectedCustomer } = this.props;
    this.setState({ selectedExtensionUser: selectedOption });
      // let objFilter = {};
      // objFilter['offset'] = 0;
      // if (typeof selectedOption.id !== 'string')
      //   this.removeFilter('extensionUserId');
      // else 
      //   objFilter['extensionUserId'] = selectedOption.id;

      // this.setFilters(objFilter);

      if (typeof selectedOption.id !== 'string' || (typeof selectedOption.id === 'string' && selectedOption.id === 'allusers')) {
        this.props.getUsers( this.state.filters, this.props.match.params.id,this.props.selectedCustomer?.defaultCustomerId, isGlobalView(this.props.selectedCustomer?.organizationName));
      } else {
        this.props.getSearchExtensionUserById( selectedOption?.customerId || this.props.match.params.id, selectedOption.id)
      }
  }

  // handleEnterClick = (userId, customerId) => {
  //   this.props.history.push(
  //     `/customers/${
  //       typeof customerId !== 'undefined'
  //         ? customerId
  //         : this.state.selectedCustomerID
  //     }/licensemanagement/${userId}`,
  //   )
  // }

  // handleClick = (tab) => {
  //   this.setState({
  //     selectedTab: tab,
  //   })
  // }

  handleChartTabClick = (tab) => {
    if (tab === this.state.selectedChartTab) {
      return false
    }
    const filters = this.state.chartFilters

    filters.getDataByPeriod =  tabs[tab].name
    filters.timespan = tabs[tab].timespan

    this.setState(
      {
        selectedChartTab: tab,
        chartFilters: filters,
      },
      () => {
        this.props.getDeployedLicensesGrowth(
          this.state.chartFilters,
          this.props.selectedCustomer.id,
          this.props.selectedCustomer?.defaultCustomerId,
          isGlobalView(this.props.selectedCustomer?.organizationName),
        )
      },
    )
  }

  // handleCopyClick = () => {
  //   const licenseKey = this.props.customerDetails.licenseKey
  //   licenseKey.select()
  //   document.execCommand('copy')
  // }

  handleClose = () => {
    this.props.unSetLicenseUserListFailMsg()
  }

  render() {
    const { usersList } = this.props;
    let selectedExtensionUser = null;
    const filters = this.props.viewType === 'chart' ? this.props.chartFilters : this.props.threatFilters
    if (filters.hasOwnProperty('extensionUserId')) {
      let selected = usersList.filter(
        (item) => item.id == filters.extensionUserId,
      )
      selectedExtensionUser = selected[0]
    }
    return (
      <>
      <div className={`${Style.navbar} pl-4 d-flex`}>
        <div className={`${ `${Style.navbarSelectedItemStyle} d-flex align-items-center` }`} style={{ marginLeft: '53px' }} > License Management </div>
      </div>
      { this. props.customerLoading ? <LoaderWrapper loading={this.props.customerLoading} waitForData={false} isFlex={true} styles={{top: '100px'}}></LoaderWrapper> : 
      <span>
      <div className={`${Style.workArea}`}>
        <div className={`${Style.headerContiner} mt-1 p-4 px-5 d-flex`}>
          <div className={`${Style.borderStyle} pl-3`}>
            <div className={`${Style.activeUsersFontStyle}`}>
              Deployed Licenses
            </div>
            <div className={`${Style.countStyle}`}>
              {
                this.props.extensionUserCountLoading ? 
                  <LoaderWrapper loading={this.props.extensionUserCountLoading}  waitForData={false} styles={{ minHeight: '60px'}} isFlex={true} placeholderText={"No license count data"} >
                  </LoaderWrapper>
                : 
                  this.props.userCount
              }
            </div>
          </div>
        </div>
        <div className="chartContainer mt-3" style={{ marginLeft: '0px' }}>
          <div className="chartRow">
            <div className="outerBox">
              <span className="headDetails">Deployed Licenses Growth</span>
              <div className="innerBox" style={{ minHeight: '415px', width: '650px' }}>
                <div className={`${Style.navbar} pl-1 d-flex`}>
                  <div onClick={() => this.handleChartTabClick(tabs.month.name)} className={` d-flex align-items-center px-3 mx-2 ${ Style.navbarFontStyle } ${ this.state.selectedChartTab === tabs.month.name ? `${Style.navbarSelectedItemStyle} ` : `` }`} > Month </div>
                  <div onClick={() => this.handleChartTabClick(tabs.year.name)} className={`d-flex align-items-center px-3 mx-2 ${ Style.navbarFontStyle } ${ this.state.selectedChartTab === tabs.year.name ? `${Style.navbarSelectedItemStyle} ` : `` }`} > Year </div>
                  <div onClick={() => this.handleChartTabClick(tabs.day.name)} className={`d-flex align-items-center px-3 mx-2 ${ Style.navbarFontStyle } ${ this.state.selectedChartTab === tabs.day.name ? `${Style.navbarSelectedItemStyle} ` : `` }`} > Day </div>
                </div> 
                <div style={{position: "relative"}}>
                <LoaderWrapper loading={this.props.dayChartLoading || this.props.monthChartLoading || this.props.yearChartLoading}  data={this.props.deployedLicensesGrowth} waitForData={true} styles={{ minHeight: '324px' }} isChart={true} placeholderText={"No licenses deployed"} >
                  <ChartLineBuild
                    deployedLicensesGrowth={ this.props.deployedLicensesGrowth ? this.props.deployedLicensesGrowth : null }
                    activeUsers={ this.props.activeUserGrowth && this.props.tokenRoles === 'PixmAdmin' ? this.props.activeUserGrowth : null }
                    name1="Deployed Licenses"
                    name2="Active User"
                    timespan={this.state.chartFilters.timespan}
                    selectedChartTab={this.state.selectedChartTab}
                  />
                </LoaderWrapper>
                </div>
              </div>
            </div>

            <div className="outerBox rightChart">
              <span className="headDetails">Browsers</span>
              <div className="innerBox" style={{ minHeight: '415px' }}> {this.props.os && this.props.browser && 
                (this.props.os['legend'].length > 0) && (this.props.browser['legend'].length > 0) ? (
                  <LMBrowserChartWrapper 
                    os={this.props.os}
                    browser={this.props.browser}
                    breakdownsLoading={this.props.breakdownsLoading}
                    breakDownsErrorMsg={this.props.breakDownsErrorMsg}
                  />
                ) : (
                  <div className="notFound headDetails">
                    <div style={{ marginTop: '34px' }}>No Data Found</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${Style.workArea} mt-2`}>
        <div className={`${Style.userLicenses}`}>User License</div>
        <div className={`mt-2 ${Style.pixmFilterBar}`}>
        <SelectBox
            role="CustomerAdmin,PixmAdmin,TenantAdmin"
            width="20%"
            optionBoxWidth=""
            selectedOption={
              !this.state.customerChanged ? selectedExtensionUser : null
            }
            options={usersList}
            defaultOption={usersList[0]}
            onSelect={this.handleSelect}
            icon={ProfileSvg}
            customerChanged={this.state.customerChanged}
            handleCustomerChange={this.handleCustomerChange}
            //iconClass="mr-3"
            type="users"
            showSearch={true}
          />
          {/* <DropDown
              name="usersSearch"
              id="usersSearch"
              role="CustomerAdmin,PixmAdmin,TenantAdmin"
              width="20%"
              optionBoxWidth="362px"
              //selectedOption={!this.state.customerChanged ? selectedExtensionUser : null}
              selectedOption={this.state.selectedExtensionUser}
              data={this.props.usersList}
              definition={{codeField: 'id', descField: 'name'}}
              // defaultOption={this.props.usersList[0]}
              onSelected={this.handleSelect}
              icon={ProfileSvg}
              selectionText="User: All"
              // customerChanged={customerChanged}
              // handleCustomerChange={this.handleCustomerChange}
              // iconClass="mr-3"
              // type="users"
              // showSearch={true}
              // DefaultText="Type Username"
          /> */}
      </div>
      </div>

      <div className={`${Style.workArea}`}>
        <GridComponent loaderFlag={this.props.loadingUserList} resetOnChange={this.props.selectedCustomer.id} data={this.props.licenseUsers} pageSize={this.state.pageSize} definition={this.usersGridDef} getPrevPageData={this.getPrevPageData} getNextPageData={this.getNextPageData} rowClick={this.rowClick} params={this.props.match.params.id} />
      </div>
      {this.props.licenseUserListApiFailed && <ConfirmPopup
        details={this.state.licenseUserApiFailMsg}
        onCloseClick={this.handleClose}
      />}
      </span>
  }
      </>
    )
  }
//   render() {
//     const {
//       totalSize,
//       loadNextUsers,
//       loadPrevUsers,
//       usersList,
//       loading,
//       dayChartLoading,
//       monthChartLoading,
//       yearChartLoading
//     } = this.props
//     const { filters } = this.state
//     const { offset, pageSize } = filters
//     const tabs = this.tabs

//     let selectedExtensionUser = null
//     if (filters.hasOwnProperty('extensionUserId')) {
//       let selected = usersList.filter(
//         (item) => item.id == filters.extensionUserId,
//       )
//       selectedExtensionUser = selected[0]
//     }
//     if (!this.props.customerDetails) return null
//     let { customerChanged } = this.state
//     return (
//       <>
//         <div className={`${Style.navbar} pl-4 d-flex`}>
//           <div
//             onClick={() => this.handleClick('License Management')}
//             className={`${
//               this.state.selectedTab === 'License Management'
//                 ? `${Style.navbarSelectedItemStyle} d-flex align-items-center`
//                 : `${Style.navbarFontStyle} d-flex align-items-center`
//             }`}
//             style={{ marginLeft: '53px' }}
//           >
//             License Management
//           </div>
//         </div>
//         {this.state.selectedTab === 'License Management' ? (
//           <div className={`mt-3 ${Style.workArea}`}>
//             <div className={`${Style.headerContiner} mt-1 p-4 px-5 d-flex`}>
//               <div className={`${Style.borderStyle} pl-3`}>
//                 <div className={`${Style.activeUsersFontStyle}`}>
//                   Deployed Licenses
//                 </div>
//                 <div className={`${Style.countStyle}`}>
//                   {this.props.userCount}
//                 </div>
//               </div>
//             </div>
//             <div className="chartContainer mt-3" style={{ marginLeft: '0px' }}>
//               <div className="chartRow">
//                 <div className="outerBox">
//                   <span className="headDetails">Deployed Licenses Growth</span>
//                   <div className="innerBox" style={{ minHeight: '415px', width: '650px' }}>
//                     <div className={`${Style.navbar} pl-1 d-flex`}>
//                       <div
//                         onClick={() => this.handleChartTabClick(tabs.month.name)}
//                         className={` d-flex align-items-center px-3 mx-2 ${
//                           Style.navbarFontStyle
//                         } ${
//                           this.state.selectedChartTab === tabs.month.name
//                             ? `${Style.navbarSelectedItemStyle} `
//                             : ``
//                         }`}
//                       >
//                         Month
//                       </div>
//                       <div
//                         onClick={() => this.handleChartTabClick(tabs.year.name)}
//                         className={`d-flex align-items-center px-3 mx-2 ${
//                           Style.navbarFontStyle
//                         } ${
//                           this.state.selectedChartTab === tabs.year.name
//                             ? `${Style.navbarSelectedItemStyle} `
//                             : ``
//                         }`}
//                       >
//                         Year
//                       </div>
//                       <div
//                         onClick={() => this.handleChartTabClick(tabs.day.name)}
//                         className={`d-flex align-items-center px-3 mx-2 ${
//                           Style.navbarFontStyle
//                         } ${
//                           this.state.selectedChartTab === tabs.day.name
//                             ? `${Style.navbarSelectedItemStyle} `
//                             : ``
//                         }`}
//                       >
//                         Day
//                       </div>
//                     </div> 
//                     <div style={{position: "relative"}}>
//                     <LoaderWrapper 
//                       loading={dayChartLoading || monthChartLoading || yearChartLoading} 
//                       data={this.props.deployedLicensesGrowth} waitForData={true}
//                       styles={{ minHeight: '324px' }} isChart={true} placeholderText={"No licenses deployed"}
//                     >
//                       <ChartLineBuild
//                         deployedLicensesGrowth={
//                           this.props.deployedLicensesGrowth
//                             ? this.props.deployedLicensesGrowth
//                             : null
//                         }
//                         activeUsers={
//                           this.props.activeUserGrowth &&
//                           this.props.tokenRoles === 'PixmAdmin'
//                             ? this.props.activeUserGrowth
//                             : null
//                         }
//                         name1="Deployed Licenses"
//                         name2="Active User"
//                         timespan={this.state.chartFilters.timespan}
//                         selectedChartTab={this.state.selectedChartTab}
//                       />
//                     </LoaderWrapper>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="outerBox rightChart">
//                   <span className="headDetails">Browsers</span>
//                   <div className="innerBox" style={{ minHeight: '415px' }}>
//                     {this.props.os &&
//                     this.props.browser &&
//                     (this.props.os['legend'].length > 0) &
//                       (this.props.browser['legend'].length > 0) ? (
//                       <LMBrowserChartWrapper
//                         os={this.props.os}
//                         browser={this.props.browser}
//                         breakdownsLoading={this.props.breakdownsLoading}
//                         breakDownsErrorMsg={this.props.breakDownsErrorMsg}
//                       />
//                     ) : (
//                       <div className="notFound headDetails">
//                         <div style={{ marginTop: '34px' }}>No Data Found</div>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className={'mt-2'}>
//               <div className={`${Style.userLicenses}`}>User License</div>
//               <div className={`mt-2 ${Style.pixmFilterBar}`}>
//                 <SelectBox
//                   role="CustomerAdmin,PixmAdmin,TenantAdmin"
//                   width="20%"
//                   optionBoxWidth="362px"
//                   //selectedOption={!this.state.customerChanged ? selectedExtensionUser : null}
//                   selectedOption={this.state.selectedExtensionUser}
//                   options={usersList}
//                   defaultOption={usersList[0]}
//                   onSelect={this.handleSelect}
//                   icon={ProfileSvg}
//                   customerChanged={customerChanged}
//                   handleCustomerChange={this.handleCustomerChange}
//                   iconClass="mr-3"
//                   type="users"
//                   showSearch={true}
//                   DefaultText="Type Username"
//                 />
//               </div>
               
//               <LoaderWrapper loading={loading} styles={{ minHeight: '380px' }} data={this.props.usersList} isFlex={true}>
//                 <div className="scrollX">
//                   <table id="tableLM" className="listTable mt-3">
//                     <thead className="tableHead">
//                       <tr>
//                         <th style={{ minWidth: '300px' }}>User</th>
//                         <th style={{ minWidth: '500px' }}>Detail</th>
//                         <th style={{ minWidth: '50px' }}></th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {this.props.usersList
//                         .slice(offset, offset + pageSize)
//                         .map((item, index) => {
//                           return (
//                             item.name !== 'User: All' && (
//                               <tr id="listLM" key={index}>
//                                 <td>
//                                 <div
//                                     className={`${Style.nameWrap}`}
//                                     data-tip
//                                     data-for={item.name}
//                                   >
//                                     <b>{item.name}</b>
//                                   </div>
//                                   <Tooltip id={item.name} data={item.name} />
//                                 </td>
//                                 <td>
//                                   Created{' '}
//                                   <i>{formatDetectionDatetime(item.created)}</i>
//                                 </td>
//                                 <td>
//                                   <img
//                                     onClick={() =>
//                                       this.handleEnterClick(
//                                         item.id,
//                                         item.customerId,
//                                       )
//                                     }
//                                     className={Style.pointer}
//                                     src={EnterIcon}
//                                     alt="next-icon"
//                                   />
//                                 </td>
//                               </tr>
//                             )
//                           )
//                         })}
//                     </tbody>
//                   </table>
                 
//                 </div>
//               </LoaderWrapper>
//               <div className={'mt-4'}>
//                 <Paginator
//                   onNext={loadNextUsers}
//                   onPrev={loadPrevUsers}
//                   filters={filters}
//                   totalSize={totalSize}
//                   cacheSize={this.props.usersList.length}
//                   from={'licenseManagement'}
//                 />
//                 </div>
//             </div>
//           </div>
//         ) : null}
//       </>
//     )
//   }
// }
}
const mapStateToProps = (state) => ({
  licenseUsers: state.entities.customers.licenseUsers,
  deployedLicensesGrowth: state.entities.customers.deployedLicensesGrowth,
  loadingUserList: state.entities.customers.loadingUserList,
  extensionUserCountLoading: state.entities.customers.extensionUsersLoading,
  // activeUserGrowth: state.entities.customers.activeUserGrowth,
  usersList: state.entities.customers.users,
  // users: state.entities.customers.users,
  selectedCustomer: state.entities.customers.selected,
  // activeUserCount: state.entities.customers.activeUserCount,
  userCount: state.entities.customers.usersCount,
  // filters: state.entities.customers.filters,
  // totalSize: state.entities.customers.totalSize,
  // customerDetails: state.entities.customers.customerDetails,
  licenseUserListApiFailed: state.entities.customers.licenseUserListApiFailed,
  orgList: state.entities.customers.list,
  // statusCode: state.entities.customers.statusCode,
  // loading: state.entities.customers.loading,
  // isGetUserOnce: state.entities.customers.isGetUserOnce,
  browser: state.entities.customers.browser,
  os: state.entities.customers.os,
  // tokenRoles: state.entities.user.roles,
  breakdownsLoading: state.entities.customers.breakdownsLoading,
  // gridLoading: state.entities.customers.gridLoading,
  threatFilters: state.entities.events.threat.filters,
  chartFilters: state.entities.events.chart.filters,
  dayChartLoading: state.entities.customers.dayChartLoading,
  monthChartLoading: state.entities.customers.monthChartLoading,
  yearChartLoading: state.entities.customers.yearChartLoading,
  customerLoading : state.entities.customers.loading,
})

const mapDispatchToProps = (dispatch) => ({
  // getActiveUsersCount: (customerId, isGlobalView) =>
  //   dispatch(getActiveUsersCount(customerId, isGlobalView)),
  getExtensionUsersCount: (selectedId, defaultCustomerId, isGlobalView) => dispatch(getExtensionUsersCount(selectedId, defaultCustomerId, isGlobalView)),
  // loadNextUsers: (offset, customerId, isGlobalView) =>
  //   dispatch(loadNextUsers(offset, customerId, isGlobalView)),
  // loadPrevUsers: (offset, customerId, isGlobalView) =>
  //   dispatch(loadPrevUsers(offset, customerId, isGlobalView)),
  getUsers: (filters, selectedId, defaultCustomerId, isGlobalView) => dispatch(getUsers(filters, selectedId, defaultCustomerId, isGlobalView)),
  getDeployedLicensesGrowth: (filters, selectedId, defaultCustomerId, isGlobalView) => dispatch(getDeployedLicensesGrowth(filters, selectedId, defaultCustomerId, isGlobalView)),
  // getActiveUserGrowth: (filters, customerId, isGlobalView) =>
  //   dispatch(getActiveUserGrowth(filters, customerId, isGlobalView)),
  setCurrentCustomer: (customerId) => dispatch(setCurrentCustomer(customerId)),
  // getCustomerDetails: (customerId) => dispatch(getCustomerDetails(customerId)),
  getSearchExtensionUserById: (customerId, userId) => dispatch(getSearchExtensionUserById(customerId, userId)),
  // getOnceUsers: () => dispatch(getOnceUsers()),
  // setCustomersFilters: (filters) => dispatch(setCustomersFilters(filters)),
  getBreakDowns: (selectedId, defaultCustomerId, isGlobalView) => dispatch(getBreakDowns(selectedId, defaultCustomerId, isGlobalView)),
  unSetLicenseUserListFailMsg: () => dispatch(unSetLicenseUserListFailMsg()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LicenseManagement)
